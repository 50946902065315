import React, { useState, createRef, useEffect } from 'react'
import classNames from 'classnames'
import Axios from 'axios'

import Button from '@mui/material/Button'
import Download from '@mui/icons-material/Download'
import FilterAltTwoTone from '@mui/icons-material/FilterAltTwoTone'
import FilterAltOffTwoTone from '@mui/icons-material/FilterAltOffTwoTone'
import CircularProgress from '@mui/material/CircularProgress'

import { useMountEffect } from 'hooks/useMountEffect'
// <CircularProgress variant="determinate" value={normalise(props.value)} />
import './Mode.css'

interface ModeProps {
  className?: string
  style?: Object

  report: string

  filterPanel?: boolean
  filterPanelLabel?: string
  filterPanelEnable?: boolean

  downloadCsv?: boolean
  downloadCsvLabel?: string

  width?: string
  height?: string
}

export const Mode: React.FC<ModeProps> = ({
  className,
  style,

  report,

  filterPanel = false,
  filterPanelEnable = false,
  filterPanelLabel = 'Filters',

  downloadCsv = false,
  downloadCsvLabel = 'CSV',

  width,
  height
}) => {
  const ref = createRef<HTMLIFrameElement>()

  const [url, setUrl] = useState<string>()
  const [csvUrl, setCSVUrl] = useState<string>()

  const [filterPanelEnabled, setFilterPanelEnabled] = useState<boolean>(filterPanelEnable)
  const [ready, setReady] = useState<boolean>(false)

  useMountEffect(() => {
    window.addEventListener('message', function (e) {
      if (e.origin === 'https://app.mode.com') {
        if (e.data['type'] === 'reportExportPaths') {
          setCSVUrl(e.origin + e.data['report_csv_export_path'])
          setReady(true)
        }
      }
    })

    const fn = async () => {
      const payload = { reportID: report, expiration: 60000, params: { } }
      const { data } = (await Axios.post(process.env.REACT_APP_MODE_EMBED_ENDPOINT ?? '', payload))
      setUrl(data.url)
    }

    fn()
  })

  useEffect(() => {
    if (ref?.current?.contentWindow && csvUrl && url) {
      /*
      // TODO this post message doesn't work
      ref.current.contentWindow.postMessage({
        name: 'getNewSize',
        current: {
          height: ref.current.offsetHeight,
          width: ref.current.offsetWidth
        }
      }, url)
      */

      ref.current.contentWindow.postMessage({
        type: 'reportFilterPanelDisplay',
        showPanel: filterPanelEnable
      }, '*')
    }
  }, [ref, csvUrl, url, filterPanelEnable])

  return (
    <div className={classNames('gt-mode', className)} style={style}>
      <div
        className='gt-mode__container'
        style={{ width: width ? `${width}px` : undefined }}
      >
        {(filterPanel || downloadCsv) &&
          <div className='gt-mode__menu-bar'>
            {filterPanel &&
              <Button
                variant='outlined'
                startIcon={filterPanelEnabled ? <FilterAltOffTwoTone /> : <FilterAltTwoTone />}
                disabled={!ready}
                onClick={() => {
                  setFilterPanelEnabled(!filterPanelEnabled)
                  if (ref?.current?.contentWindow) {
                    ref.current.contentWindow.postMessage({
                      type: 'reportFilterPanelDisplay',
                      showPanel: filterPanelEnabled
                    }, '*')
                  }
                }}
              >
                {filterPanelLabel}
              </Button>}
            {downloadCsv &&
              <Button
                href={csvUrl}
                variant='outlined'
                startIcon={<Download />}
                disabled={!csvUrl || !ready}
                style={{ marginLeft: 'auto' }}
              >
                {downloadCsvLabel}
              </Button>}
          </div>}

          {!ready &&
            <div className='gt-mode__loader'>
              <CircularProgress />
            </div>}

          <iframe
            ref={ref}
            title={report}
            src={url}
            className='gt-mode__embed'
            style={{ height: height ? `${height}px` : undefined, width: width ? `${width}px` : undefined }}
          />
      </div>
    </div>
  )
}

export default Mode
