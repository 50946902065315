
export type ValueOf<T> = T[keyof T]
export type FilterValue = string | number
export type FilterOptions = FilterValue[] | Record<string, FilterValue>

export type Filters = Record<string, {
  type?: 'Select',
  default?: FilterValue,
  value?: FilterValue | FilterValue[],
  options?: FilterOptions,
  properties?: object
}>

export const localFilter = <T>(data: T[] | undefined, filters: Filters): T[] | undefined => {
  if (data === undefined) {
    return
  }

  if (Object.entries(filters).length === 0) {
    return data
  }

  const f = Object.entries(filters)
  return data.filter((item) => {
    const res = f.filter(([name, filter]) => {
      if (!filter.value || !name) {
        return true
      }

      // @ts-expect-error
      if (!item[name]) {
        return false
      }

      // @ts-expect-error
      if (filter.value.includes(item[name])) {
        return true
      }

      return false
    })

    return f.length === res.length
  })
}
