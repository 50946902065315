// import { Broken } from 'components/Broken'
import { useData } from 'hooks/useData'

export const withData = (WrappedComponent: any) => {
  return (props: any) => {
    const { data, filters, filterData } = useData()
    /*
    // TODO improve error handler
    if (error) {
      return <Broken />
    }
    */

    // TODO implement loader and ready states
    return (
      <WrappedComponent
        {...data}
        filters={filters}
        filterData={filterData}
        {...props}
      />
    )
  }
}

export default withData
