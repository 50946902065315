import { useState, useEffect } from 'react'
import classNames from 'classnames'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import SelectZ, { SelectChangeEvent } from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import { FormHelperText } from '@mui/material'

import { Filters } from 'utils/filter'

import './Select.css'

// type MenuItemProp = 'key' | 'value' | 'label'
// type CurrentOption = string | number
export interface SelectProps {
  className?: string
  style?: Object

  name: string
  // options: Array<Record<MenuItemProp, any>>
  options: object,
  defaultValue?: string
  label?: string
  formHelperText?: string | null

  filters: Filters
  filterData: Function
}

// TODO add support for multiple?
// TODO properly figure out support for numbers vs strings
// TODO fix x position to be inside of select??
// TODO figure out better solution to typecast and handle numbers
export function Select ({
  className,
  style,

  name,
  options,
  defaultValue,
  label = '',
  formHelperText = null,
  filters = {},
  filterData
}: SelectProps): JSX.Element {
  const [currentOption, setCurrentOption] = useState(defaultValue ?? '')
  const [currentOptions, setCurrentOptions] = useState({})

  const filter = filters?.[name]
  useEffect(() => {
    if (options) {
      setCurrentOptions(options)
    } else if (filter?.options) {
      setCurrentOptions(filter.options)
    }
  }, [options, filter?.options])

  useEffect(() => {
    if (filter?.value) {
      setCurrentOption(String(filter.value))
    }
  }, [filter?.value])

  const handleChange = (event: SelectChangeEvent): void => {
    const value = event.target.value
    if (filterData) {
      filterData(name, [Number.isNaN(parseInt(value)) ? value : parseInt(value)])
    }
  }

  const handleClearClick = (): void => {
    setCurrentOption('')
    if (filterData) {
      filterData(name, undefined)
    }
  }

  return (
    <div className={classNames('gt-select', className)} style={style}>
      <div className='gt-select__input'>
        <FormControl fullWidth>
          <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
          <SelectZ
            className='gt-select-button'
            value={currentOption ?? ''}
            label={label ?? ''}
            onChange={handleChange}
          >
            {currentOptions && Object.entries(currentOptions).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {String(value)}
              </MenuItem>
            ))}
          </SelectZ>
          <FormHelperText>{formHelperText}</FormHelperText>
        </FormControl>
      </div>
      <IconButton
        onClick={handleClearClick}
        sx={{ visibility: currentOption ? 'visible' : 'hidden' }}
        className='gt-select__clear'
      >
        <ClearIcon />
      </IconButton>
    </div>
  )
}

export default Select
