import set from 'lodash.set'

import type { Filters } from 'utils/filter'
import type { DataProviderProps } from 'providers/data'

export const buildEmbedProps = (dataset: object): DataProviderProps => {
  const filters: Filters = {}
  const props: DataProviderProps = {
    initialData: undefined,
    params: { filters },
    properties: {}
  }

  Object.entries(dataset).forEach(([key, value]) => {
    if (!value) {
      return
    }

    if (key === 'embed') {
      return
    }

    if (key === 'type') {
      props.type = value
      return
    }

    // TODO any validation on this?
    if (key === 'src') {
      props.src = value
      return
    }

    if (key === 'data') {
      try {
        props.initialData = JSON.parse(value)
        return
      } catch (err) {
        console.warn(`gt-embed :: Error when trying to parse data attribute`)
        console.warn(err)
      }
    }

    // TODO refactor this with setIn or something instead
    if (key.startsWith('filters.') && props?.params?.filters) {
      const [field, name] = key.replace('filters.', '').split('.')
      if (!props.params.filters[field]) {
        props.params.filters[field] = { type: 'Select' }
      }

      if (name === 'options') {
        if (value.startsWith('{')) {
          props.params.filters[field][name] = JSON.parse(value)
        } else {
          const values = value?.split(',')
          props.params.filters[field][name] = values.map((value: string) => {
            if (Number.isNaN(parseInt(value))) {
              return value
            }

            return parseInt(value)
          })
        }

        return
      }

      if (['value', 'default', 'type'].includes(name)) {
        // TODO figure out why this has a TS error and fix
        // @ts-expect-error
        props.params.filters[field][name] = value
      }

      if (!props.params.filters[field].properties) {
        props.params.filters[field].properties = {}
      }

      // @ts-expect-error
      props.params.filters[field].properties[name] = value
      return
    }

    if (props.properties) {
      if (value.startsWith('{') || value.startsWith('[')) {
        set(props.properties, key, JSON.parse(value))
      } else {
        if (value === 'true') {
          value = true
        } else if (value === 'false') {
          value = false
        }

        set(props.properties, key, value)
      }
    }
  })

  return props
}
