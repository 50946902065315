import Axios from 'axios'
import { CacheAxiosResponse, setupCache } from 'axios-cache-interceptor'

// TODO fix stupid hack where setupCache isn't set in test env
export const xhr = setupCache ? setupCache(Axios.create({
  timeout: 90000
})) : Axios.create({
  timeout: 90000
})

export const Get = async (url: string, params = {}): Promise<CacheAxiosResponse<any, any>> => {
  return await xhr.get(url, { params })
}

export const Head = async (url: string, params = {}): Promise<CacheAxiosResponse<any, any>> => {
  return await xhr.head(url, { params })
}

export default xhr
