import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import './index.css'
import App from './App'

import { DataProvider } from 'providers/data'
import { buildEmbedProps } from 'utils/embed'

// Filter components
import { Select } from 'components/Select'

// Visualization components
import { Mode } from 'components/Mode'

// For local development
const rootEl = document.getElementById('root')
if (rootEl) {
  const root = ReactDOM.createRoot(rootEl as HTMLElement)
  root.render(
    <App />
  )
}

const components: Record<string, React.FC>= {
  // Filter components
  Select,

  // Visualization components
  Mode
}

try {
  const embeds = document.querySelectorAll('section[data-embed]')
  for (let i = 0; i < embeds.length; ++i) {
    const node: Element = embeds[i]
    // @ts-expect-error
    const dataset: DOMStringMap = node.dataset
    const props = buildEmbedProps(dataset)
    const type = props?.type ?? 'div'
    const Component: React.FC = components[type]

    const root = ReactDOM.createRoot(node as HTMLElement)
    root.render(
      <DataProvider {...props}>
        {props?.params?.filters &&
          <div style={{ display: 'flex' }}>
            {Object.entries(props.params.filters).map(([name, value], index) => {
              const type = value?.type ?? 'div'
              const Component: React.FC = components[type]
              return (
                <Component key={index} {...value?.properties} {...{ name }} />
              )
            })}
          </div>}
        <Component {...props.properties} />
      </DataProvider>
    )
  }
} catch (err) {
  console.warn(`gt-embed :: Error when trying to parse page embeds`)
  console.warn(err)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
