// import { useMemo } from 'react'
// import { DataProvider } from 'providers/data'
// import { CompositionBar } from 'components/CompositionBar'
// import { MetricsBar } from 'components/MetricsBar'
// import { LineChart } from 'components/LineChart'
// import { TextBlock } from 'components/TextBlock'
// import { Context } from 'chartjs-plugin-datalabels'
// import { Select } from 'components/Select'
// import { Mode } from 'components/Mode'

const App = (): JSX.Element => {
  /*
  const urls = useMemo(() => ({
    generosityActionIndex: 'https://public.dev.gtdata.org/givingpulse/2023/04/generosity-action-index.json',
    generositySentimentIndex: 'https://public.dev.gtdata.org/givingpulse/2023/04/generosity-sentiment-index.json',
    generousBehaviors: 'https://public.dev.gtdata.org/givingpulse/2023/04/generous-behaviors.json',
    generousBehaviorsWeekly: 'https://public.dev.gtdata.org/givingpulse/2023/04/generous-behaviors-weekly.json',
    givingProfiles: 'https://public.dev.gtdata.org/givingpulse/2023/04/giving-profiles.json',
    textBlockPage1: 'https://public.dev.gtdata.org/givingpulse/2023/04/text-block-page-1.json',
  }), [])
  */

  // const report = '2612c89f9803' // Dollars x Donor Size
  // const report = 'd4796f2f9572' // [DEV] Florida Survey

  return (
    <div>
      {/*
      <div style={{ height: '925px' }}>
        <Mode
          report={report}
          filterPanel
          filterPanelLabel='Test Filter Panel'
          downloadCsv
          downloadCsvLabel='Test CSV'

          // width='900'
          height='500'
        />
      </div>
      */}

      {/* <DataProvider
        initialData={[
          { name: 'buffy', age: 4, color: 'black' },
          { name: 'boots', age: 21, color: 'grey' },
          { name: 'pikachu', age: 12, color: 'white' },
          { name: 'buster', age: 10, color: 'white' }
        ]}

        params={{
          filters: {
            name: {
              default: 'buffy',
              value: 'buffy',
              // options: { buffy: 'Buffy', boots: 'Boots', pikachu: 'Pikachu', buster: 'Buster' }
              options: ['buffy', 'boots', 'pikachu', 'buster']
            }
          }
        }}
      >
        <Select
          name='name'
          label='Choose kitty'
          // defaultValue='buffy'
          // filters={{name: ['buffy']}}
          // options={[
          //   { key: 'boots', value: 'boots', label: 'boots' },
          //   { key: 'buffy', value: 'buffy', label: 'buffy' },
          //   { key: 'pikachu', value: 'pikachu', label: 'pikachu' },
          //   { key: 'buster', value: 'buster', label: 'buster' }
          // ]}
        />
      </DataProvider> */}

      {/* <DataProvider src={urls.generosityActionIndex}>
        <LineChart
          options={{
            xlabel: 'Week of collection',
            ylabel: 'Generosity Action Index',
            medianLines: [
              {
                label: '2023 median',
                value: 1.6,
                color: '#377162',
                xOffset: 0.2
              },
              {
                label: '2022 median',
                value: 1.59,
                color: '#166cad',
                xOffset: 0.2,
                yOffset: -9
              }
            ],
            pointLabels: {
              show: true,
              fontSize: 20
            },
            hideMedianDatasets: true
          }}
        />
      </DataProvider>

      <DataProvider src={urls.generositySentimentIndex}>
        <LineChart
          options={{
            xlabel: 'Week of collection',
            medianLines: [
              {
                label: '2023 median',
                color: '#377162',
                value: 0.01,
                lineWeight: 1,
                yOffset: 20
              },
              {
                label: '2022 median',
                color: '#166cad',
                value: 0.03,
                lineWeight: 1
              }
            ],
            pointLabels: true,
            hideMedianDatasets: true
          }}
        />
      </DataProvider>

      <DataProvider src={urls.givingProfiles}>
        <CompositionBar
          options={{
            roundedCorners: true
          }}
        />
      </DataProvider>

      <DataProvider src={urls.generousBehaviorsWeekly}>
        <LineChart
          options={{
            legend: true,
            pointLabels: false
          }}
        />
      </DataProvider> */}

      {/* <LineChart
        // header='Line Chart Component'
        // subHeader='Shows line chart with a border and some title information'
        // description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fames ac turpis egestas maecenas pharetra convallis posuere morbi. Urna et pharetra pharetra massa massa ultricies mi. Erat nam at lectus urna. In metus vulputate eu scelerisque felis imperdiet. Elementum integer enim neque volutpat ac tincidunt vitae. Pulvinar mattis nunc sed blandit libero volutpat sed. Nulla aliquet enim tortor at auctor urna nunc id cursus. Enim nec dui nunc mattis enim. Faucibus a pellentesque sit amet porttitor eget dolor morbi non. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus.'

        title='title'
        caption='aaa'

        style={{ margin: '2em' }}
        data={[
          {
            label: "Current Month",
            data: [
              {"2022-05-06": 10},
              {"2022-05-13": 15},
              {"2022-05-20": 20},
              {"2022-05-27": 25}
            ]
          },
          {
            label: "Previous Month",
            data: [
              {"2022-05-06": 5},
              {"2022-05-13": 10},
              {"2022-05-20": 15},
              {"2022-05-27": 20}
            ]
          },
          {
            label: "Year-to-date median",
            data: [
              {"2022-05-06": 6},
              {"2022-05-13": 6},
              {"2022-05-20": 6},
              {"2022-05-27": 6}
            ]
          }
        ]}
      /> */}

      {/* <CompositionBar
        header='Composition Bar Component'
        subHeader='Shows a bar with a bunch of additional context'
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fames ac turpis egestas maecenas pharetra convallis posuere morbi. Urna et pharetra pharetra massa massa ultricies mi. Erat nam at lectus urna. In metus vulputate eu scelerisque felis imperdiet. Elementum integer enim neque volutpat ac tincidunt vitae. Pulvinar mattis nunc sed blandit libero volutpat sed. Nulla aliquet enim tortor at auctor urna nunc id cursus. Enim nec dui nunc mattis enim. Faucibus a pellentesque sit amet porttitor eget dolor morbi non. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus.'
        data={[
          {
            label: 'Cluster 1',
            value: 50,
            color: 'rgba(237, 24, 46, 0.7)',
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Odio ut enim blandit volutpat maecenas volutpat. Dolor morbi non arcu risus quis varius quam quisque.',
            bullets: [
              'Sed nisi lacus sed viverra tellus in hac habitasse.',
              'Sit amet risus nullam eget felis eget nunc lobortis mattis.'
            ]
          },
          {
            label: 'Cluster 2',
            value: 30,
            color: 'rgba(51, 71, 125, 0.7)',
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            description: 'Massa sed elementum tempus egestas sed sed. Pellentesque pulvinar pellentesque habitant morbi tristique. Nibh sed pulvinar proin gravida hendrerit. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus.'
          },
          {
            label: 'Cluster 3',
            value: 20,
            color: 'rgba(11, 184, 198, 0.7)',
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            bullets: [
              'Pharetra pharetra massa massa ultricies mi quis hendrerit dolor.',
              'Auctor elit sed vulputate mi sit amet mauris.',
              'Proin fermentum leo vel orci porta non pulvinar.',
              'Massa tincidunt nunc pulvinar sapien.'
            ]
          }
        ]}
      /> */}

      {/* <MetricsList
        header='Metrics List Component'
        subHeader='Shows a list of metrics with nested bullets with a header and description'
        description='Longer form description for the entire thing (optional)'
        data={[
          {
            label: 'Financial',
            bullets: [
              'Financial Line one',
              'Financial Line two'
            ]
          },
          {
            label: 'Items',
            bullets: [
              'Items Line one',
              'Items Line two'
            ]
          }
        ]}
      /> */}

      {/* <MetricsBar
        header='Metrics Bar Component'
        subHeader='Shows a list metrics with an icon, label, current value and change'
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fames ac turpis egestas maecenas pharetra convallis posuere morbi. Urna et pharetra pharetra massa massa ultricies mi. Erat nam at lectus urna. In metus vulputate eu scelerisque felis imperdiet. Elementum integer enim neque volutpat ac tincidunt vitae. Pulvinar mattis nunc sed blandit libero volutpat sed. Nulla aliquet enim tortor at auctor urna nunc id cursus. Enim nec dui nunc mattis enim. Faucibus a pellentesque sit amet porttitor eget dolor morbi non. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus.'
        data={[
          {
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            label: "Financial",
            color: '#4a86e8',
            currentValue: "44.9%",
            changeValue: "-21.4%",
            changeLabel: "over previous month",
          },
          {
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            label: "Items",
            color: '#eaa257',
            currentValue: "47.8%",
            changeValue: "-14.4%",
            changeLabel: "over previous month",
          },
          {
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            label: "Volunteer",
            color: '#6ba950',
            currentValue: "24.5%",
            changeValue: "-29.4%",
            changeLabel: "over previous month",
          },
          {
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            label: "Advocacy",
            color: '#ce0a0a',
            currentValue: "32.5%",
            changeValue: "+1.6%",
            changeLabel: "over previous month",
          },
          {
            icon: 'https://public.dev.gtdata.org/icons/baseline_attach_money_black_36dp.png',
            label: "Other",
            color: '#8f7dc4',
            currentValue: "28.5%",
            changeValue: "-12.3%",
            changeLabel: "over previous month",
          }
        ]}
      /> */}

      {/*
        <DataProvider src={lineChartData} chartLibrary={library} />
      <div className='chart-div'>
        <DataProvider src={lineChartData} chartLibrary={library} />
      </div>
      <div className='chart-div'>
        <DataProvider src={barChartData} chartLibrary={library} />
      </div>
      <div className='chart-div'>
        <DataProvider src={radarChartData} chartLibrary={library} />
      </div>
      <DataProvider src={src1} chartLibrary={library}>
        <Select
          name='posts'
          options={options}
          defaultValue='2'
        />
        <RadioButton
          name='gender'
          options={radio}
          defaultValue='0'
        />
        <Slider
          name='age'
          defaultValue={25}
          max={80}
        />
      </DataProvider>
      */}
    </div>
  )
}

export default App
